<template>
        <Header></Header>
        <div class="pageTitle" > <font-awesome-icon icon="caret-right"/> Historique scores</div>

        <div class="pageBodyContainer">
        <LoadPdfScore ref="LoadPdfScore"></LoadPdfScore>

        <!-- Score vs. Charte graphique -->
            <div class="card">
                <DataTable ref="dt" :value="gridData"  dataKey="id" 
                    :paginator="true" 
                    :rows="10"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    :rowsPerPageOptions="[10,50,100]"
                    currentPageReportTemplate="Lignes de {first} à {last} sur un total de {totalRecords}" 
                    :filters="filters" class="p-datatable-sm"
                    responsiveLayout="scroll"
                    filterDisplay="row">
                    <template #header>
                        Statut: <Dropdown style="width:200px" v-model="statutFilter" optionValue="value" optionLabel="name" :options="[{value:'',name:'Tout'}].concat(optionStatut)"/>
                        Filtre: <Dropdown style="width:200px" v-model="selectedFilter" optionValue="value" optionLabel="name" :options="[{value:'Tout',name:'Tout'},{name:'Dernier score',value:'DernierScore'}]"/>
                        &nbsp;<P-Button flow="up" label="Exporter en excel" tooltip="Export scores/Societes" icon="pi pi-file-excel" class="p-button-outlined p-button-rounded p-button-success p-mr-2" @click="exportExcel" />
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <div class="table-header p-d-flex p-flex-column p-flex-md-row">
                            <!-- <h5 class="p-mb-2 p-m-md-0 p-as-md-center">
                            </h5> -->
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Recherche..." />
                            </span>
                            &nbsp;
                            <!-- <span class="p-input-icon-left" v-if="currentClient.activerWorkflowValidation">
                                Filtre financier:&nbsp;
                                <Dropdown v-model="filtre1" optionLabel="name" optionValue="value" :options="[{name:'Non scoré',value:-1},{name:'Tout',value:null},{name:'Non Soumis',value:0},{name:'En attente de validation',value:1},{name:'Validé',value:2}]"  @change="filterStatutsFinancier"/>
                            </span>
                            &nbsp;
                            <span class="p-input-icon-left" v-if="currentClient.activerWorkflowValidation">
                                Filtre Qualitatif:&nbsp; 
                                <Dropdown v-model="filtre2" optionLabel="name" optionValue="value" :options="[{name:'Non scoré',value:-1},{name:'Tout',value:null},{name:'Non Soumis',value:0},{name:'En attente de validation',value:1},{name:'Validé',value:2}]" @change="filterStatutsQualitatif"/>
                            </span> -->
                        </div>
                    </template>

                    <!-- <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column> -->
                    <Column :exportable="false" style="width:170px">
                        <template #body="slotProps">
                            <span flow="right" tooltip="Consulter score" >
                                <P-Button flow="up" tooltip="Consulter score" icon="pi pi-eye" class="p-button-outlined p-button-rounded p-button-success p-mr-2" @click="showPdf(slotProps.data)" />
                            </span>
                            <span flow="right" tooltip="Supprimer score" v-if="currentUser.jsonUserRights.titre && currentUser.jsonUserRights.titre == 'Administrateur'">
                                <P-Button  icon="pi pi-trash" class="p-button-outlined p-button-rounded p-button-danger p-mr-2" @click.prevent="confirmDeleteSelected(slotProps.data)" />
                            </span>
                            <span flow="right" tooltip="Télécharger rapport PDF" v-if="slotProps.data.etatPublication" >
                                <P-Button icon="pi pi-print" class="p-button-outlined p-button-rounded" @click.prevent="scorePdf(slotProps.data.id)" />
                            </span>
                            <span flow="right" v-if="!slotProps.data.etatPublication">
                                <P-Button icon="pi pi-pencil" class="p-button-outlined p-button-rounded" @click.prevent="editScoring(slotProps.data)" /> 
                            </span>
                        </template>
                    </Column>
                    <Column field="raisonSociale" header="Raison sociale" :sortable="true" style="min-width:10rem"></Column>
                    <Column field="score" header="Score" :sortable="true" style="min-width:8rem"> 
                    <template #body="slotProps">
                        <div class="p-d-flex">
                            <div class="p-mr-2 circle" :style="{background: slotProps.data.kpiColor?slotProps.data.kpiColor:'lightgrey'} "></div>
                            <div class="p-mr-2">{{this.formatDecimal0(slotProps.data.score)}}/10</div>
                        </div>
                    </template>
                    </Column>
                    <!-- <Column header="Publié">
                        <template #filter>
                            <Dropdown style="width:100px" v-model="filters['etatPublication'].value" optionValue="value" optionLabel="name" :options="[{name:'Tout',value:null},{name:'Oui',value:1},{name:'Non',value:0}]"/>
                        </template>
                        <template #body="slotProps">
                            <b v-if="slotProps.data.etatPublication">
                                <b style="color:green">Oui</b>
                            </b>
                            <b v-else style="color:red">Non</b>
                        </template>
                    </Column> -->
                    <Column header="Statut">
                        <template #filter>
                            <Dropdown style="width:100px" v-model="filters['statutId'].value" optionValue="value" optionLabel="name" :options="optionStatut"/>
                        </template>
                        <template #body="slotProps">
                            {{ slotProps.data.statut }}
                        </template>
                    </Column>
                    <Column header="Statut financier" field="scoreFinancier" :sortable="true" style="min-width:8rem">
                        <template #filter="{ filterModel, filterCallback }">
                            <Dropdown v-if="currentClient.activerWorkflowValidation" style="width:100px" v-model="filters.etatValidationFinanciere.value" optionLabel="name" optionValue="value" :options="[{name:'Tout',value:null},{name:'Non Soumis',value:0},{name:'En attente de validation',value:1},{name:'Validé',value:2}]"/>
                            <Dropdown v-else style="width:100px" v-model="filters.estScoreFinancier.value" optionLabel="name" optionValue="value" :options="[{name:'Tout',value:null},{name:'Non Scoré',value:0},{name:'Scoré',value:1}]"/>
                        </template>
                        <template #body="slotProps">
                            <div style="text-align:center;">
                                <!-- <span v-if="slotProps.data.jsonFinancier">{{ slotProps.data.scoreFinancier }}%</span>
                                <br v-if="slotProps.data.jsonFinancier"/> -->
                                <span v-if="!slotProps.data.jsonFinancier && currentClient.activerWorkflowValidation">Non Scoré</span>
                                <span v-else-if="!slotProps.data.etatValidationFinanciere && currentClient.activerWorkflowValidation">Non Soumis</span>
                                <span v-else-if="slotProps.data.etatValidationFinanciere==1 && currentClient.activerWorkflowValidation">En attente de validation</span>
                                <span v-else-if="slotProps.data.etatValidationFinanciere==2 && currentClient.activerWorkflowValidation">Validé</span>
                                <span v-else-if="!slotProps.data.estScoreFinancier && !currentClient.activerWorkflowValidation">Non Scoré</span>
                                <span v-else-if="slotProps.data.estScoreFinancier && !currentClient.activerWorkflowValidation">Scoré</span>
                        </div>
                        </template>
                    </Column>
                    <Column header="Statut qualitatif" field="scoreQualitatif" :sortable="true" style="min-width:8rem">
                        <template #filter="{ filterModel, filterCallback }">
                            <Dropdown v-if="currentClient.activerWorkflowValidation" style="width:100px" v-model="filters.etatValidationQualitative.value" optionLabel="name" optionValue="value" :options="[{name:'Tout',value:null},{name:'Non Soumis',value:0},{name:'En attente de validation',value:1},{name:'Validé',value:2}]"/>
                            <Dropdown v-else style="width:100px" v-model="filters.estScoreQualitatif.value" optionLabel="name" optionValue="value" :options="[{name:'Tout',value:null},{name:'Non Scoré',value:0},{name:'Scoré',value:1}]"/>
                        </template>
                        <template #body="slotProps">
                            <div style="text-align:center;">
                                <!-- <span v-if="slotProps.data.jsonQualitatif">{{ slotProps.data.scoreQualitatif }}%</span>
                                <br v-if="slotProps.data.jsonQualitatif"/> -->
                                <span v-if="!slotProps.data.jsonQualitatif && currentClient.activerWorkflowValidation">Non Scoré</span>
                                <span v-else-if="!slotProps.data.etatValidationQualitative && currentClient.activerWorkflowValidation">Non Soumis</span>
                                <span v-else-if="slotProps.data.etatValidationQualitative==1 && currentClient.activerWorkflowValidation">En attente de validation</span>
                                <span v-else-if="slotProps.data.etatValidationQualitative==2 && currentClient.activerWorkflowValidation">Validé</span>
                                <span v-else-if="!slotProps.data.estScoreQualitatif && !currentClient.activerWorkflowValidation">Non Scoré</span>
                                <span v-else-if="slotProps.data.estScoreQualitatif && !currentClient.activerWorkflowValidation">Scoré</span>
                        </div>
                        </template>
                    </Column>
                    <Column field="dateScore" header="Date score" :sortable="true" style="min-width:8rem">
                    <template #body="slotProps">
                        {{slotProps.data.dateScore?this.formatDate(slotProps.data.dateScore):""}}
                    </template>
                    </Column>
                    <!-- <Column field="dateCreation" header="Date Creation" :sortable="true" style="min-width:16rem"></Column> -->
                    <Column field="utilisateur" header="Analyste" :sortable="true" style="min-width:12rem"></Column>
                    <Column field="limiteCreditRecommandee" header="Limite de crédit" :sortable="true" style="min-width:12rem">
                        <template #body="slotProps">
                            <div style="text-align:right">
                            {{formatDecimal0(arrondiDixMilles(slotProps.data.limiteCreditRecommandee))}}
                            </div>
                        </template> 
                    </Column>
                    
                    <!-- <Column field="poidsScoreFinancier" header="Poids Score Financier" :sortable="true" style="min-width:16rem"></Column>
                    <Column field="poidsScoreQualitatif" header="Poids Score Qualitatif" :sortable="true" style="min-width:16rem"></Column>
                    
                    <Column field="dateValidite" header="Date Validite" :sortable="true" style="min-width:16rem"> </Column>
                    <Column field="natureRisque" header="Nature Risque" :sortable="true" style="min-width:16rem"> </Column>
                    <Column field="recommandation" header="Recommandation" :sortable="true" style="min-width:16rem"> </Column> -->

                    <template #footer>
                        Total {{gridData.length}}
                    </template>
                    
                </DataTable>
            </div>

        <!-- Delete Dialog -->
        

        <Dialog v-model:visible="showEditDialogFlag" :style="{width: '800px'}" :header="'Consultation'" :modal="true" class="p-fluid">
            <LoadPdfScore :historiqueScoreId="selectedGridItem.id" ref="LoadPdfScore" :key="showEditDialogFlag+'-'+selectedGridItem.id"></LoadPdfScore>
            <!-- <div class="p-field">
                <label for="score">Score :</label>
                {{this.formatDecimal0(selectedGridItem.score)}}/10
                <ErrorMessage name="score" class="error-feedback" />
                
            </div>

            <div class="p-field">
                <label for="dateScore">Date score :</label>
                {{this.formatDate(selectedGridItem.dateScore)}}
                <ErrorMessage name="dateScore" class="error-feedback" />
            </div>

            <div class="p-field">
                <label for="dateValidite" class="p-mb-3">Date validité :</label>
                {{this.formatDate(selectedGridItem.dateValidite)}}
                <ErrorMessage name="dateValidite" class="error-feedback" />
            </div>

            <div class="p-field">
                <label for="scoreFinancier" class="p-mb-3">Score financier :</label>
                {{selectedGridItem.scoreFinancier+"%"}}
                <ErrorMessage name="scoreFinancier" class="error-feedback" />
            </div>

            <div class="p-field">
                <label for="scoreQualitatif" class="p-mb-3">Score qualitatif :</label>
                {{selectedGridItem.scoreQualitatif+"%"}}
                <ErrorMessage name="scoreQualitatif" class="error-feedback" />
            </div>

            <div class="p-field">
                <label for="poidsScoreFinancier" class="p-mb-3">Poids score financier :</label>
                {{selectedGridItem.poidsScoreFinancier+"%"}}
                <ErrorMessage name="poidsScoreFinancier" class="error-feedback" />
            </div>
            
            <div class="p-field">
                <label for="poidsScoreQualitatif" class="p-mb-3">Poids score qualitatif :</label>
                {{selectedGridItem.poidsScoreQualitatif+"%"}}
                <ErrorMessage name="poidsScoreQualitatif" class="error-feedback" />
            </div>
            
            <div class="p-field">
                <label for="natureRisque" class="p-mb-3">Nature risque :</label>
                {{selectedGridItem.natureRisque}}
                <ErrorMessage name="natureRisque" class="error-feedback" />
            </div>
            
            <div class="p-field">
                <label for="recommandation" class="p-mb-3">Recommandation :</label>
                {{selectedGridItem.recommandation}}
                <ErrorMessage name="recommandation" class="error-feedback" />
            </div>

            <div class="p-field">
                <label for="caEscompte" class="p-mb-3">CA Escompte :</label>
                {{selectedGridItem.caEscompte?selectedGridItem.caEscompte:"NA"}}
                <ErrorMessage name="caEscompte" class="error-feedback" />
            </div>

            <div class="p-field">
                <label for="delaiPaiement" class="p-mb-3">Delai Paiement :</label>
                {{selectedGridItem.delaiPaiement?selectedGridItem.delaiPaiement:"NA"}}
                <ErrorMessage name="delaiPaiement" class="error-feedback" />
            </div>

            <div class="p-field">
                <label class="p-mb-3">Limite crédit souhaitée :</label>
                {{LimiteCreditSouhait?LimiteCreditSouhait:0}}
            </div> -->

        </Dialog>
        
        
        <div class="errorMessage" ref="errorMessage"> {{errorMessage}}</div>
    </div>

    
    
</template>

<script>

import Header from "./Header.vue";
import CRUDService from "../1-common-components/crud.service";
import {_} from 'vue-underscore';
import { Button } from '@progress/kendo-vue-buttons';
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { KendoDataSource } from '@progress/kendo-datasource-vue-wrapper';
import kendo from '@progress/kendo-ui'
import { Chart } from '@progress/kendo-charts-vue-wrapper';
import { TabStrip } from '@progress/kendo-layout-vue-wrapper';
import {FilterMatchMode,FilterOperator} from 'primevue/api';
import Dialog from 'primevue/dialog'
import ConfirmDialog from 'primevue/confirmdialog';
import { Field, Form, ErrorMessage, } from 'vee-validate';
import LoadPdfScore from "./LoadPdfScore.vue";
import { saveExcel } from '@progress/kendo-vue-excel-export';


export default {
  name: "ClientHistoriqueScore",
  components: {
    Header, 
        'k-button': Button,
        'kendo-grid': Grid,
        'kendo-grid-column': GridColumn,
        
        'kendo-datasource': KendoDataSource,
        'chart': Chart,
        'kendo-tabstrip': TabStrip,
        'LoadPdfScore' : LoadPdfScore,

        Dialog,
        ConfirmDialog,
        'V-Field':Field,
        ErrorMessage,
        
  },
  props:   ['societeId'],
  data() {
        return {
            filtre1:{name:'Tout',value:null},
            filtre2:{name:'Tout',value:null},
            gridData: [],
            showEditDialogFlag: false,
            selectedGridItem: {id:0},
            selectedGridInitialState: {id:0},
            filters: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'etatValidationQualitative': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'etatValidationFinanciere': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'etatPublication': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'statutId': {value: null, matchMode: FilterMatchMode.EQUALS},
                'estScoreFinancier': {value: null, matchMode: FilterMatchMode.CONTAINS},
                'estScoreQualitatif': {value: null, matchMode: FilterMatchMode.CONTAINS}
            },
            selectedFilter:'Tout',
            statutFilter:null,
            optionStatut:[],
            errorMessage: "",
            currentClient:{id:0}
        } // end result
    }, //end data()
    beforeMount(){
    },
    async mounted() {
        await this.fnLoadCurrentClient();
        this.loadGridData();
        CRUDService.list("getRefStatuts").then((response) => {
            this.optionStatut = response.data.map(function(d){
                return {value:d.id,name:d.nom}
            })
            this.optionStatut.push({value:null,name:'Tout'})
        })
    },
   // -------- grid init
    //--------------------------- Created --------------------------
    created() {
        this.log("----------- created()");

    },
    computed:
    {
        LimiteCreditSouhait(){
            if(isNaN(this.selectedGridItem.delaiPaiement) || isNaN(this.selectedGridItem.caEscompte))
            {
                return 0;
            }
            return Math.round(this.selectedGridItem.caEscompte / 360 * this.selectedGridItem.delaiPaiement);
        }
    },
  //----------Methods---------------------
  methods:{
    // filterStatutsFinancier(ev)
    // {
    //     console.log(ev.value)
    //     this.filters.etatValidationFinanciere.value = ev.value;
    // },
    // filterStatutsQualitatif(ev)
    // {
    //     console.log(ev.value)
    //     this.filters.etatValidationQualitative.value = ev.value;
    // },
    exportExcel : function() {
          let ref=this;
          
          CRUDService.list("exportScoresWithSocietes/" + this.selectedFilter + "/" + this.statutFilter).then(function(result){
       
              let resultData = result.data;
              var CountResults = resultData.length;

              if(CountResults >0)
              {
                const data = resultData.map(function(d){
                  Object.keys(d).forEach(function(attr){

                    if(attr.includes('date'))
                    {
                      d[attr] = new Date(d[attr])
                    }
                    
                  })

                  delete d.societeId
                  delete d.statutId
                  return d

                })

                const fileName = 'Consultation';
                // const exportType =  exportFromJSON.types.xls;

                // exportFromJSON({ data, fileName, exportType });
                
                var columns = Object.keys(resultData[0]).map(function(d){
                  return { field: d, title: d}
                });

                saveExcel({
                    data: data,
                    fileName: fileName,
                    columns: columns
                });
                
              }
              else
              {
                console.error("ExportExcel data failed loaded: "+result.data);
                ref.toastError(result.data);
              }
            })
        },
    fnLoadCurrentClient()
        {
            var endpoint = "currentclientcustom";
            
            return CRUDService.get(0, endpoint).then(
                (response) => {
                    this.log("----------------- fnLoadClient reponse");
                    this.log(response.data);
                    this.currentClient = response.data
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
        },
    editScoring : function(selectedItem)
    {
      var str = '/client-scoring/'+selectedItem.societeId + '/'+selectedItem.id
      this.$router.push(str);
    },
    loadGridData: function(e)
        {
            this.log("------transportRead");
            this.log(e);
            CRUDService.list('societieswithscores').then(
                (response) => {
                    this.log(response.data);
                    this.gridData = response.data;

                    if(!this.currentClient.activerWorkflowValidation)
                    {
                        this.gridData = this.gridData.map(function(d){
                            var obj = {...d}
                            obj['estScoreFinancier'] = obj.jsonFinancier?1:0
                            obj['estScoreQualitatif'] = obj.jsonQualitatif?1:0
                            return obj
                        })
                    }
                
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
            
        },
        openEditDialog(selectedItem){
            this.log("openEditDialog");
            this.log(selectedItem);
            this.showEditDialogFlag = true;
            this.selectedGridItem = selectedItem;
            this.selectedGridItemInitialState = { ...this.selectedGridItem }; //this is an object copy using decomposition
        },
        closeEditDialog(){
            this.log("closeEditDialog");
            this.showEditDialogFlag = false;
        },
        scorePdf : function(id)
        {
            this.$refs.LoadPdfScore.loadPdfFromSociete(id);
        },
        showPdf : function(selectedItem)
        {
            this.selectedGridItem = selectedItem;
            this.showEditDialogFlag = true;
            // this.$refs.ShowPdfScore.loadPdfFromSociete(selecteditem.id);
        },
        confirmDeleteSelected(selectedItem){
            this.log("confirmDeleteSelected: " + selectedItem);
            this.log(selectedItem);
            this.selectedGridItem = selectedItem

            this.$confirm.require({
                message: 'Etes-vous sûr de vouloir supprimer le score ?',
                header: 'Confirmation',
                icon: 'pi pi-info-circle',
                acceptLabel:'Oui',
                rejectLabel:'Non',
                acceptClass: 'p-button-danger',
                accept: () => {
                    this.deleteGridItem();
                    this.$confirm.close();
                },
                reject: () => {
                    this.$confirm.close();
                }
            });
    },
    deleteGridItem()
    {
        this.log("deleteGridItem");
        CRUDService.delete(this.selectedGridItem,'HistoriqueScoreClient').then(
            (response) => {
                this.log(response.data);
                this.gridData = this.gridData.filter(d => d.id != this.selectedGridItem.id);
                this.toastSuccess("Le score a été supprimé avec succès");
            },
            (error) => {
                this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
        );
    },

        
    
  }
};
</script>


<!-- ########################## <Style> ########################## -->
<style >

.p-column-filter-menu-button
{
    display:none;
}
.circle {
   width: 20px;
   height: 20px; 
   -moz-border-radius: 70px; 
   -webkit-border-radius: 70px; 
   border-radius: 70px;
}
</style>